import { Grid } from "@mui/material";
import React, { FC } from "react";
import CardImage from "./CardImage";

const CardTable: FC<{
  dataName: string;
  data: any;
}> = ({ dataName, data }) => {
  return (
    <Grid container spacing={2}>
      {data.currentData().map((item: any, index: any) => (
        <Grid item md={4} key={index}>
          <CardImage dataName={dataName} {...item} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CardTable;
