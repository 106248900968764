import React from "react";

function BgHeader() {
  return (
    <div
      style={{
        position: "absolute",
        transform: "translate(-2%, -7%)",
        top: -10,
        right: 0,
      }}
    >
      <svg
        fill="none"
        height="1352"
        viewBox="0 0 1371 1352"
        width="1371"
        xmlns="http://www.w3.org/2000/svg"
        className="nextui-c-PJLV nextui-c-PJLV-ijrJnjS-css"
      >
        <path
          d="M1892.3 1135.79L2050.33 851.108C2053.28 845.808 2054.01 839.56 2052.37 833.724L1926.31 384.994C1925.02 380.404 1922.34 376.329 1918.63 373.331L1601.23 116.627C1594.51 111.191 1585.31 109.983 1577.41 113.498L1318.02 228.99C1310.12 232.506 1304.86 240.152 1304.4 248.784L1282.79 656.427C1282.54 661.188 1283.77 665.909 1286.32 669.938L1535.44 1063.87C1538.68 1069 1543.82 1072.63 1549.72 1073.99L1867.03 1147.04C1877.02 1149.34 1887.32 1144.75 1892.3 1135.79Z"
          opacity="0.0144928"
          stroke="url(#paint0_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1843 1148.95L2014.92 875.181C2018.15 870.04 2019.21 863.831 2017.88 857.908L1916.64 407.325C1915.59 402.66 1913.12 398.437 1909.56 395.244L1608.27 125.038C1601.85 119.274 1592.73 117.594 1584.68 120.688L1321.17 221.84C1313.11 224.934 1307.46 232.28 1306.54 240.863L1263.45 643.264C1262.94 648.018 1263.92 652.813 1266.26 656.981L1492.53 1059.57C1495.5 1064.86 1500.45 1068.77 1506.29 1070.43L1817.23 1158.84C1827.07 1161.64 1837.57 1157.61 1843 1148.95Z"
          opacity="0.0289855"
          stroke="url(#paint1_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1793.61 1160.53L1978.7 898.343C1982.21 893.376 1983.61 887.222 1982.59 881.228L1906.01 430.15C1905.21 425.422 1902.95 421.062 1899.54 417.682L1614.92 134.949C1608.8 128.873 1599.8 126.728 1591.6 129.392L1324.76 216.093C1316.56 218.757 1310.54 225.783 1309.16 234.293L1245.08 630.329C1244.32 635.064 1245.05 639.919 1247.18 644.217L1450.36 1054.16C1453.06 1059.61 1457.81 1063.76 1463.56 1065.72L1767.42 1169.04C1777.07 1172.32 1787.73 1168.86 1793.61 1160.53Z"
          opacity="0.0434783"
          stroke="url(#paint2_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1744.22 1170.54L1941.74 920.558C1945.52 915.781 1947.25 909.701 1946.55 903.653L1894.4 453.414C1893.84 448.635 1891.8 444.15 1888.57 440.59L1621.08 146.324C1615.28 139.953 1606.42 137.35 1598.1 139.578L1328.73 211.758C1320.41 213.987 1314.03 220.673 1312.2 229.086L1227.68 617.673C1226.66 622.375 1227.14 627.278 1229.05 631.694L1409 1047.69C1411.42 1053.28 1415.96 1057.68 1421.61 1059.93L1717.67 1177.66C1727.12 1181.41 1737.91 1178.52 1744.22 1170.54Z"
          opacity="0.057971"
          stroke="url(#paint3_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1694.9 1179L1904.1 941.798C1908.13 937.224 1910.18 931.236 1909.8 925.149L1881.78 477.06C1881.48 472.244 1879.67 467.644 1876.61 463.912L1626.68 159.124C1621.23 152.475 1612.52 149.423 1604.11 151.21L1332.98 208.842C1324.57 210.63 1317.85 216.959 1315.58 225.25L1211.22 605.345C1209.94 609.999 1210.16 614.937 1211.85 619.46L1368.5 1040.21C1370.63 1045.92 1374.94 1050.56 1380.49 1053.09L1668.08 1184.71C1677.3 1188.92 1688.19 1186.61 1694.9 1179Z"
          opacity="0.0724638"
          stroke="url(#paint4_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1645.75 1185.93L1865.82 962.033C1870.1 957.678 1872.48 951.798 1872.42 945.69L1868.16 501.032C1868.11 496.191 1866.54 491.487 1863.66 487.592L1631.65 173.304C1626.55 166.397 1618.03 162.904 1609.55 164.248L1337.43 207.347C1328.95 208.69 1321.93 214.645 1319.21 222.79L1195.68 593.391C1194.15 597.984 1194.1 602.943 1195.56 607.562L1328.91 1031.77C1330.74 1037.6 1334.82 1042.46 1340.23 1045.28L1618.73 1190.21C1627.7 1194.88 1638.66 1193.14 1645.75 1185.93Z"
          opacity="0.0869565"
          stroke="url(#paint5_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1596.84 1191.35L1826.98 981.242C1831.49 977.117 1834.18 971.363 1834.45 965.252L1853.51 525.272C1853.72 520.419 1852.39 515.624 1849.71 511.575L1635.92 188.819C1631.19 181.671 1622.87 177.75 1614.34 178.646L1342.02 207.268C1333.49 208.164 1326.17 213.729 1323.03 221.705L1181.02 581.857C1179.24 586.375 1178.93 591.342 1180.15 596.045L1290.27 1022.45C1291.8 1028.37 1295.63 1033.44 1300.91 1036.53L1569.7 1194.2C1578.39 1199.3 1589.4 1198.15 1596.84 1191.35Z"
          opacity="0.101449"
          stroke="url(#paint6_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1548.26 1195.28L1787.62 999.402C1792.36 995.522 1795.36 989.91 1795.95 983.812L1837.83 549.722C1838.3 544.871 1837.22 539.997 1834.74 535.803L1639.43 205.616C1635.07 198.247 1626.98 193.909 1618.43 194.357L1346.66 208.6C1338.11 209.048 1330.52 214.208 1326.95 221.992L1167.23 570.786C1165.2 575.216 1164.63 580.177 1165.6 584.952L1252.63 1012.29C1253.85 1018.29 1257.42 1023.56 1262.54 1026.92L1521.07 1196.71C1529.47 1202.23 1540.48 1201.65 1548.26 1195.28Z"
          opacity="0.115942"
          stroke="url(#paint7_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1500.08 1197.77L1747.81 1016.5C1752.76 1012.87 1756.06 1007.42 1756.98 1001.35L1821.12 574.324C1821.85 569.489 1821.01 564.548 1818.74 560.219L1642.09 223.641C1638.12 216.071 1630.28 211.33 1621.73 211.33L1351.27 211.33C1342.72 211.33 1334.88 216.071 1330.91 223.641L1154.26 560.219C1151.99 564.548 1151.15 569.489 1151.88 574.324L1216.02 1001.35C1216.94 1007.42 1220.24 1012.87 1225.19 1016.5L1472.92 1197.77C1481.01 1203.69 1491.99 1203.69 1500.08 1197.77Z"
          opacity="0.130435"
          stroke="url(#paint8_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1452.38 1198.85L1707.62 1032.52C1712.77 1029.16 1716.36 1023.88 1717.59 1017.86L1803.37 599.022C1804.35 594.216 1803.78 589.221 1801.72 584.767L1643.87 242.837C1640.29 235.087 1632.71 229.956 1624.19 229.509L1355.78 215.442C1347.26 214.996 1339.19 219.307 1334.82 226.641L1142.09 550.198C1139.58 554.412 1138.48 559.319 1138.96 564.202L1180.49 989.712C1181.08 995.83 1184.11 1001.45 1188.88 1005.33L1425.32 1197.43C1433.08 1203.73 1444.01 1204.3 1452.38 1198.85Z"
          opacity="0.144928"
          stroke="url(#paint9_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1405.24 1198.55L1667.09 1047.44C1672.42 1044.37 1676.3 1039.28 1677.85 1033.32L1784.58 623.758C1785.83 618.995 1785.51 613.959 1783.67 609.391L1644.69 263.143C1641.51 255.233 1634.22 249.728 1625.75 248.837L1360.12 220.918C1351.64 220.027 1343.37 223.897 1338.62 230.974L1130.68 540.758C1127.94 544.846 1126.58 549.705 1126.8 554.623L1146.05 977.429C1146.33 983.579 1149.06 989.36 1153.64 993.478L1378.35 1195.73C1385.76 1202.39 1396.61 1203.53 1405.24 1198.55Z"
          opacity="0.15942"
          stroke="url(#paint10_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1358.71 1196.93L1626.3 1061.28C1631.8 1058.49 1635.95 1053.61 1637.82 1047.73L1764.77 648.476C1766.27 643.768 1766.21 638.704 1764.6 634.032L1644.5 284.496C1641.74 276.448 1634.76 270.584 1626.35 269.253L1364.21 227.734C1355.8 226.402 1347.35 229.821 1342.23 236.621L1120 531.937C1117.03 535.884 1115.41 540.682 1115.38 545.622L1112.74 964.568C1112.7 970.735 1115.14 976.659 1119.51 981.01L1332.08 1192.71C1339.12 1199.72 1349.85 1201.42 1358.71 1196.93Z"
          opacity="0.173913"
          stroke="url(#paint11_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1312.87 1194.03L1585.3 1074.01C1590.95 1071.52 1595.36 1066.86 1597.54 1061.08L1743.93 673.118C1745.68 668.48 1745.89 663.401 1744.52 658.637L1643.27 306.83C1640.92 298.665 1634.26 292.46 1625.95 290.694L1367.98 235.861C1359.67 234.095 1351.06 237.054 1345.59 243.557L1110 523.767C1106.81 527.561 1104.93 532.285 1104.65 537.233L1080.58 951.2C1080.22 957.366 1082.36 963.417 1086.51 967.991L1286.57 1188.44C1293.22 1195.77 1303.81 1198.02 1312.87 1194.03Z"
          opacity="0.188406"
          stroke="url(#paint12_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1267.78 1189.9L1544.15 1085.65C1549.94 1083.46 1554.6 1079.04 1557.09 1073.37L1722.09 697.63C1724.09 693.075 1724.56 687.994 1723.42 683.15L1640.93 330.077C1639 321.816 1632.68 315.289 1624.49 313.093L1371.37 245.27C1363.17 243.074 1354.43 245.567 1348.63 251.756L1100.65 516.279C1097.25 519.909 1095.12 524.544 1094.57 529.488L1049.59 937.391C1048.91 943.541 1050.74 949.704 1054.66 954.49L1241.88 1182.96C1248.13 1190.59 1258.55 1193.38 1267.78 1189.9Z"
          opacity="0.202899"
          stroke="url(#paint13_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1223.51 1184.59L1502.92 1096.19C1508.82 1094.32 1513.73 1090.14 1516.52 1084.61L1699.24 721.958C1701.49 717.499 1702.23 712.43 1701.34 707.517L1637.45 354.167C1635.95 345.833 1629.98 339.002 1621.93 336.385L1374.3 255.925C1366.25 253.308 1357.41 255.329 1351.29 261.186L1091.91 509.503C1088.31 512.956 1085.92 517.488 1085.12 522.416L1019.79 923.211C1018.79 929.327 1020.3 935.588 1023.98 940.573L1198.07 1176.33C1203.91 1184.24 1214.13 1187.56 1223.51 1184.59Z"
          opacity="0.217391"
          stroke="url(#paint14_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1180.1 1178.17L1461.65 1105.64C1467.66 1104.09 1472.79 1100.18 1475.88 1094.79L1675.42 746.048C1677.91 741.698 1678.91 736.655 1678.27 731.684L1632.8 379.028C1631.72 370.643 1626.13 363.527 1618.23 360.497L1376.72 267.789C1368.83 264.759 1359.91 266.304 1353.49 271.812L1083.73 503.463C1079.93 506.728 1077.3 511.145 1076.24 516.043L991.176 908.728C989.862 914.796 991.056 921.137 994.488 926.31L1155.19 1168.61C1160.61 1176.78 1170.61 1180.61 1180.1 1178.17Z"
          opacity="0.231884"
          stroke="url(#paint15_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1137.61 1170.69L1420.42 1114.01C1426.52 1112.79 1431.86 1109.15 1435.23 1103.92L1650.64 769.848C1653.37 765.621 1654.63 760.616 1654.24 755.601L1626.94 404.588C1626.29 396.173 1621.08 388.793 1613.37 385.36L1378.57 280.821C1370.86 277.388 1361.89 278.454 1355.2 283.598L1076.08 498.185C1072.09 501.251 1069.21 505.54 1067.9 510.394L963.77 894.014C962.141 900.015 963.011 906.421 966.183 911.77L1113.3 1159.87C1118.29 1168.27 1128.03 1172.61 1137.61 1170.69Z"
          opacity="0.246377"
          stroke="url(#paint16_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1096.08 1162.21L1379.27 1121.31C1385.44 1120.42 1390.98 1117.07 1394.63 1112.02L1624.92 793.308C1627.88 789.216 1629.41 784.263 1629.27 779.216L1619.85 430.772C1619.62 422.349 1614.8 414.725 1607.3 410.9L1379.78 294.977C1372.28 291.152 1363.28 291.737 1356.33 296.504L1068.89 493.69C1064.73 496.546 1061.62 500.693 1060.05 505.491L937.569 879.136C935.629 885.056 936.173 891.509 939.076 897.021L1072.45 1150.16C1076.98 1158.78 1086.45 1163.6 1096.08 1162.21Z"
          opacity="0.26087"
          stroke="url(#paint17_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1055.57 1152.8L1338.27 1127.57C1344.49 1127.01 1350.21 1123.95 1354.13 1119.1L1598.29 816.379C1601.47 812.433 1603.26 807.547 1603.38 802.48L1611.49 457.503C1611.69 449.095 1607.28 441.25 1600 437.044L1380.31 310.21C1373.03 306.004 1364.03 306.11 1356.85 310.485L1062.14 489.995C1057.82 492.632 1054.48 496.625 1052.65 501.354L912.574 864.164C910.326 869.986 910.541 876.471 913.168 882.132L1032.67 1139.57C1036.75 1148.37 1045.91 1153.66 1055.57 1152.8Z"
          opacity="0.275362"
          stroke="url(#paint18_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M1016.12 1142.52L1297.47 1132.78C1303.71 1132.57 1309.6 1129.82 1313.78 1125.17L1570.78 839.013C1574.18 835.227 1576.23 830.42 1576.6 825.345L1601.86 484.708C1602.48 476.336 1598.49 468.29 1591.45 463.718L1380.11 326.47C1373.06 321.898 1364.09 321.525 1356.7 325.497L1055.78 487.118C1051.3 489.526 1047.74 493.353 1045.66 497.999L888.779 849.166C886.229 854.875 886.113 861.375 888.456 867.171L993.998 1128.16C997.621 1137.11 1006.46 1142.85 1016.12 1142.52Z"
          opacity="0.289855"
          stroke="url(#paint19_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M977.75 1131.44L1256.91 1136.99C1263.17 1137.12 1269.21 1134.68 1273.64 1130.25L1542.41 861.166C1546.02 857.551 1548.33 852.837 1548.96 847.767L1590.93 512.308C1591.97 503.993 1588.41 495.771 1581.63 490.845L1379.11 343.708C1372.33 338.782 1363.41 337.933 1355.83 341.492L1049.76 485.073C1045.13 487.243 1041.36 490.891 1039.04 495.442L866.177 834.211C863.33 839.79 862.882 846.289 864.935 852.206L956.478 1115.99C959.635 1125.08 968.123 1131.25 977.75 1131.44Z"
          opacity="0.304348"
          stroke="url(#paint20_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M940.505 1119.64L1216.65 1140.21C1222.91 1140.68 1229.09 1138.57 1233.75 1134.37L1513.22 882.793C1517.04 879.361 1519.59 874.752 1520.49 869.701L1578.71 540.227C1580.16 531.991 1577.03 523.614 1570.53 518.351L1377.29 361.867C1370.79 356.604 1361.95 355.284 1354.19 358.42L1044.02 483.87C1039.27 485.793 1035.29 489.251 1032.73 493.694L844.754 819.365C841.617 824.8 840.837 831.28 842.594 837.303L920.134 1103.14C922.82 1112.35 930.939 1118.92 940.505 1119.64Z"
          opacity="0.318841"
          stroke="url(#paint21_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M904.412 1107.17L1176.75 1142.47C1182.98 1143.28 1189.28 1141.5 1194.17 1137.55L1483.25 903.853C1487.26 900.615 1490.06 896.126 1491.21 891.105L1565.17 568.39C1567.04 560.253 1564.35 551.745 1558.14 546.159L1374.6 380.894C1368.39 375.308 1359.65 373.522 1351.75 376.227L1038.54 483.517C1033.66 485.186 1029.49 488.443 1026.69 492.766L824.495 804.694C821.076 809.969 819.964 816.413 821.42 822.529L884.993 1089.69C887.205 1098.98 894.935 1105.94 904.412 1107.17Z"
          opacity="0.333333"
          stroke="url(#paint22_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M869.493 1094.12L1137.24 1143.8C1143.44 1144.94 1149.83 1143.5 1154.93 1139.81L1452.53 924.308C1456.72 921.274 1459.76 916.916 1461.17 911.939L1550.33 596.718C1552.6 588.701 1550.35 580.085 1544.46 574.195L1371 400.729C1365.1 394.838 1356.49 392.593 1348.47 394.861L1033.25 484.022C1028.27 485.43 1023.92 488.474 1020.88 492.664L805.38 790.261C801.686 795.362 800.246 801.754 801.394 807.947L851.075 1075.7C852.811 1085.06 860.134 1092.38 869.493 1094.12Z"
          opacity="0.347826"
          stroke="url(#paint23_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M835.768 1080.54L1098.18 1144.22C1104.31 1145.71 1110.79 1144.61 1116.08 1141.19L1421.09 944.119C1425.45 941.3 1428.74 937.087 1430.4 932.166L1534.18 625.137C1536.84 617.261 1535.05 608.561 1529.49 602.382L1366.45 421.315C1360.89 415.136 1352.42 412.441 1344.31 414.265L1028.12 485.386C1023.05 486.526 1018.52 489.348 1015.26 493.393L787.386 776.13C783.426 781.043 781.659 787.366 782.498 793.62L818.396 1061.25C819.656 1070.65 826.554 1078.31 835.768 1080.54Z"
          opacity="0.362319"
          stroke="url(#paint24_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M803.253 1066.53L1059.6 1143.78C1065.65 1145.6 1072.19 1144.85 1077.68 1141.71L1388.99 963.254C1393.51 960.659 1397.02 956.603 1398.94 951.751L1516.74 653.573C1519.79 645.856 1518.44 637.096 1513.22 630.648L1360.93 442.59C1355.71 436.142 1347.42 433.006 1339.24 434.384L1023.09 487.612C1017.94 488.478 1013.25 491.068 1009.77 494.956L770.486 762.361C766.27 767.072 764.18 773.31 764.705 779.61L786.969 1046.42C787.754 1055.83 794.211 1063.8 803.253 1066.53Z"
          opacity="0.376812"
          stroke="url(#paint25_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M771.959 1052.14L1021.55 1142.5C1027.51 1144.66 1034.09 1144.26 1039.74 1141.41L1356.25 981.679C1360.93 979.318 1364.66 975.431 1366.83 970.66L1498.01 681.95C1501.43 674.412 1500.54 665.615 1495.67 658.917L1354.42 464.494C1349.55 457.795 1341.46 454.23 1333.23 455.157L1018.12 490.697C1012.91 491.285 1008.06 493.632 1004.37 497.352L754.651 749.013C750.19 753.509 747.78 759.646 747.99 765.977L756.801 1031.28C757.113 1040.68 763.116 1048.94 771.959 1052.14Z"
          opacity="0.391304"
          stroke="url(#paint26_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M741.893 1037.45L984.066 1140.43C989.906 1142.91 996.513 1142.87 1002.32 1140.32L1322.94 999.366C1327.75 997.247 1331.7 993.54 1334.12 988.863L1478.01 710.198C1481.8 702.857 1481.36 694.047 1476.86 687.118L1346.88 486.963C1342.38 480.034 1334.51 476.051 1326.26 476.528L1013.16 494.638C1007.91 494.942 1002.92 497.039 999.02 500.579L739.848 736.143C735.152 740.412 732.428 746.431 732.321 752.776L727.897 1015.9C727.739 1025.26 733.275 1033.79 741.893 1037.45Z"
          opacity="0.405797"
          stroke="url(#paint27_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M713.058 1022.53L947.183 1137.6C952.89 1140.41 959.503 1140.72 965.452 1138.48L1289.08 1016.29C1294.03 1014.42 1298.18 1010.9 1300.84 1006.33L1456.76 738.244C1460.91 731.118 1460.92 722.32 1456.8 715.181L1338.3 509.936C1334.18 502.797 1326.55 498.409 1318.31 498.436L1008.17 499.427C1002.89 499.444 997.766 501.282 993.675 504.632L726.041 723.805C721.121 727.834 718.089 733.72 717.665 740.065L700.254 1000.36C699.631 1009.66 704.689 1018.42 713.058 1022.53Z"
          opacity="0.42029"
          stroke="url(#paint28_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M685.452 1007.46L910.935 1134.06C916.491 1137.18 923.092 1137.85 929.164 1135.92L1254.73 1032.42C1259.79 1030.81 1264.14 1027.5 1267.04 1023.04L1434.29 766.021C1438.78 759.127 1439.24 750.363 1435.51 743.034L1328.66 533.348C1324.93 526.019 1317.57 521.243 1309.35 520.82L1003.11 505.056C997.805 504.783 992.569 506.355 988.291 509.504L713.191 712.052C708.06 715.83 704.726 721.567 703.984 727.895L673.868 984.728C672.787 993.949 677.356 1002.92 685.452 1007.46Z"
          opacity="0.434783"
          stroke="url(#paint29_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M659.069 992.303L875.354 1129.84C880.741 1133.27 887.31 1134.3 893.489 1132.69L1219.93 1047.74C1225.1 1046.39 1229.63 1043.29 1232.77 1038.97L1410.62 793.46C1415.44 786.815 1416.34 778.108 1413.01 770.612L1317.96 557.136C1314.62 549.64 1307.55 544.489 1299.39 543.62L997.926 511.514C992.619 510.948 987.28 512.247 982.825 515.186L701.256 700.933C695.927 704.449 692.299 710.021 691.24 716.317L648.73 969.08C647.197 978.196 651.27 987.343 659.069 992.303Z"
          opacity="0.449275"
          stroke="url(#paint30_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M633.902 977.128L840.467 1125C845.669 1128.72 852.187 1130.11 858.455 1128.83L1184.74 1062.23C1189.99 1061.15 1194.71 1058.28 1198.07 1054.1L1385.79 820.497C1390.92 814.116 1392.26 805.49 1389.33 797.847L1306.18 581.237C1303.25 573.594 1296.47 568.087 1288.39 566.776L992.579 518.786C987.285 517.928 981.858 518.946 977.235 521.664L690.194 690.494C684.679 693.738 680.765 699.131 679.391 705.379L624.826 953.486C622.85 962.473 626.42 971.772 633.902 977.128Z"
          opacity="0.463768"
          stroke="url(#paint31_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M609.935 962.006L806.3 1119.56C811.3 1123.58 817.748 1125.32 824.088 1124.37L1149.19 1075.87C1154.52 1075.07 1159.41 1072.43 1162.99 1068.41L1359.82 847.07C1365.25 840.967 1367.03 832.445 1364.51 824.678L1293.32 605.587C1290.8 597.82 1284.35 591.974 1276.37 590.226L987.027 526.858C981.763 525.705 976.261 526.438 971.482 528.927L679.956 680.78C674.271 683.741 670.079 688.941 668.392 695.126L602.14 938.014C599.729 946.85 602.791 956.274 609.935 962.006Z"
          opacity="0.478261"
          stroke="url(#paint32_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M587.152 947.002L772.877 1113.59C777.659 1117.88 784.017 1119.97 790.412 1119.37L1113.35 1088.65C1118.74 1088.14 1123.78 1085.74 1127.58 1081.88L1332.77 873.117C1338.48 867.308 1340.69 858.911 1338.58 851.042L1279.38 630.123C1277.28 622.254 1271.16 616.088 1263.31 613.912L981.231 535.712C976.012 534.265 970.45 534.707 965.524 536.958L670.495 671.831C664.653 674.501 660.194 679.494 658.197 685.599L580.649 922.732C577.816 931.396 580.367 940.916 587.152 947.002Z"
          opacity="0.492754"
          stroke="url(#paint33_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M565.533 932.184L740.217 1107.12C744.766 1111.68 751.015 1114.12 757.447 1113.85L1077.26 1100.57C1082.7 1100.34 1087.88 1098.2 1091.88 1094.51L1304.66 898.583C1310.64 893.079 1313.27 884.83 1311.58 876.882L1264.37 654.783C1262.68 646.835 1256.92 640.368 1249.22 637.771L975.151 545.328C969.993 543.588 964.385 543.734 959.325 545.74L661.761 663.684C655.776 666.056 651.06 670.827 648.757 676.839L560.33 907.705C557.085 916.177 559.123 925.764 565.533 932.184Z"
          opacity="0.507246"
          stroke="url(#paint34_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M545.053 917.613L708.338 1100.21C712.639 1105.02 718.761 1107.8 725.211 1107.88L1040.96 1111.61C1046.44 1111.67 1051.75 1109.79 1055.95 1106.28L1275.54 923.413C1281.77 918.227 1284.81 910.146 1283.54 902.141L1248.28 679.506C1247.01 671.501 1241.63 664.755 1234.1 661.747L968.748 555.683C963.668 553.652 958.029 553.499 952.846 555.251L653.701 656.375C647.589 658.441 642.627 662.978 640.023 668.881L541.154 892.998C537.51 901.257 539.035 910.884 545.053 917.613Z"
          opacity="0.521739"
          stroke="url(#paint35_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M525.683 903.351L677.255 1092.89C681.292 1097.94 687.268 1101.06 693.72 1101.48L1004.52 1121.77C1010.01 1122.12 1015.45 1120.5 1019.84 1117.2L1245.47 947.555C1251.93 942.698 1255.37 934.806 1254.52 926.768L1231.13 704.231C1230.29 696.193 1225.29 689.188 1217.96 685.78L961.988 566.755C957 564.436 951.347 563.981 946.053 565.473L646.26 649.935C640.038 651.688 634.84 655.979 631.941 661.757L523.089 878.671C519.061 886.698 520.074 896.336 525.683 903.351Z"
          opacity="0.536232"
          stroke="url(#paint36_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M507.394 889.457L646.979 1085.22C650.74 1090.5 656.552 1093.94 662.985 1094.71L967.979 1131.04C973.47 1131.69 979.013 1130.35 983.592 1127.25L1214.49 970.962C1221.16 966.444 1224.99 958.761 1224.56 950.711L1212.94 728.9C1212.52 720.85 1207.91 713.609 1200.8 709.813L954.834 578.518C949.956 575.914 944.303 575.155 938.91 576.379L639.385 644.391C633.067 645.826 627.647 649.859 624.458 655.498L506.101 864.783C501.702 872.56 502.206 882.182 507.394 889.457Z"
          opacity="0.550725"
          stroke="url(#paint37_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M490.151 875.989L617.519 1077.25C620.991 1082.74 626.621 1086.5 633.018 1087.61L931.387 1139.43C936.864 1140.38 942.501 1139.32 947.255 1136.43L1182.65 993.588C1189.52 989.418 1193.71 981.963 1193.71 973.925V753.454C1193.71 745.416 1189.52 737.961 1182.65 733.791L947.255 590.945C942.501 588.061 936.864 586.996 931.387 587.947L633.018 639.769C626.621 640.88 620.991 644.643 617.519 650.13L490.151 851.39C485.397 858.901 485.397 868.478 490.151 875.989Z"
          opacity="0.565217"
          stroke="url(#paint38_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M473.917 863L588.882 1069.02C592.053 1074.7 597.483 1078.77 603.826 1080.23L894.794 1146.95C900.243 1148.2 905.961 1147.42 910.878 1144.76L1150 1015.39C1157.05 1011.58 1161.61 1004.37 1162.03 996.367L1173.48 777.838C1173.9 769.834 1170.12 762.189 1163.51 757.66L939.218 604.008C934.606 600.848 929 599.477 923.45 600.15L627.103 636.089C620.642 636.872 614.816 640.356 611.069 645.676L475.198 838.547C470.107 845.774 469.608 855.28 473.917 863Z"
          opacity="0.57971"
          stroke="url(#paint39_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M458.651 850.542L561.072 1060.57C563.93 1066.44 569.144 1070.81 575.414 1072.6L858.249 1153.59C863.655 1155.14 869.439 1154.65 874.511 1152.22L1116.61 1036.34C1123.82 1032.89 1128.72 1025.94 1129.56 1018L1152.26 801.997C1153.09 794.05 1149.74 786.239 1143.41 781.365L930.694 617.676C926.238 614.247 920.681 612.569 915.072 612.959L621.579 633.369C615.073 633.821 609.064 637.015 605.049 642.155L461.199 826.302C455.786 833.231 454.798 842.64 458.651 850.542Z"
          opacity="0.594203"
          stroke="url(#paint40_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M444.313 838.664L534.089 1051.97C536.625 1057.99 541.604 1062.65 547.783 1064.79L821.802 1159.37C827.147 1161.21 832.985 1161.03 838.2 1158.84L1082.53 1056.39C1089.88 1053.31 1095.11 1046.64 1096.36 1038.78L1130.07 825.88C1131.32 818.011 1128.41 810.058 1122.37 804.857L921.656 631.918C917.372 628.227 911.878 626.244 906.224 626.346L616.39 631.623C609.854 631.742 603.678 634.637 599.404 639.583L448.108 814.705C442.392 821.321 440.921 830.605 444.313 838.664Z"
          opacity="0.608696"
          stroke="url(#paint41_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M430.857 827.41L507.932 1043.24C510.135 1049.41 514.865 1054.35 520.935 1056.82L785.498 1164.3C790.768 1166.44 796.644 1166.55 801.992 1164.61L1047.82 1075.52C1055.29 1072.81 1060.83 1066.44 1062.48 1058.67L1106.96 849.435C1108.61 841.666 1106.14 833.596 1100.42 828.086L912.077 646.702C907.98 642.756 902.566 640.469 896.881 640.281L611.475 630.863C604.927 630.646 598.597 633.233 594.075 637.974L435.875 803.799C429.875 810.089 427.933 819.224 430.857 827.41Z"
          opacity="0.623188"
          stroke="url(#paint42_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M418.235 816.824L482.596 1034.45C484.458 1040.75 488.924 1045.95 494.866 1048.74L749.385 1168.39C754.563 1170.83 760.463 1171.24 765.933 1169.56L1012.54 1093.69C1020.11 1091.36 1025.95 1085.31 1028 1077.66L1082.94 872.614C1084.99 864.968 1082.96 856.805 1077.57 851.005L901.935 661.994C898.04 657.802 892.722 655.213 887.02 654.732L606.775 631.094C600.231 630.542 593.764 632.813 589.002 637.335L424.452 793.625C418.186 799.576 415.785 808.537 418.235 816.824Z"
          opacity="0.637681"
          stroke="url(#paint43_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M406.4 806.945L458.075 1025.63C459.588 1032.04 463.774 1037.49 469.572 1040.6L713.507 1171.67C718.578 1174.39 724.488 1175.12 730.067 1173.7L976.757 1110.89C984.404 1108.95 990.519 1103.22 992.957 1095.71L1058.05 895.373C1060.49 887.869 1058.91 879.639 1053.87 873.569L891.207 677.759C887.528 673.33 882.322 670.442 876.617 669.666L602.231 632.32C595.709 631.432 589.119 633.382 584.129 637.674L413.784 784.22C407.272 789.822 404.425 798.586 406.4 806.945Z"
          opacity="0.652174"
          stroke="url(#paint44_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M395.3 797.809L434.359 1016.83C435.517 1023.32 439.409 1029.01 445.045 1032.44L677.909 1174.14C682.858 1177.16 688.761 1178.19 694.439 1177.04L940.526 1127.1C948.233 1125.54 954.607 1120.15 957.425 1112.8L1032.33 917.668C1035.15 910.327 1034.02 902.054 1029.34 895.735L879.874 693.962C876.426 689.306 871.347 686.124 865.654 685.051L597.784 634.542C591.3 633.32 584.604 634.94 579.397 638.992L403.818 775.62C397.081 780.862 393.801 789.405 395.3 797.809Z"
          opacity="0.666667"
          stroke="url(#paint45_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M384.883 789.449L411.437 1008.09C412.235 1014.65 415.819 1020.56 421.276 1024.29L642.633 1175.84C647.444 1179.14 653.326 1180.48 659.09 1179.6L903.913 1142.3C911.661 1141.12 918.274 1136.08 921.461 1128.92L1005.82 939.458C1009 932.299 1008.33 924.01 1004.02 917.463L867.918 710.566C864.714 705.695 859.779 702.223 854.112 700.851L593.374 637.757C586.946 636.201 580.16 637.488 574.748 641.288L394.498 767.853C387.556 772.728 383.86 781.028 384.883 789.449Z"
          opacity="0.681159"
          stroke="url(#paint46_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M375.095 781.894L389.295 999.453C389.727 1006.07 392.992 1012.18 398.253 1016.21L607.72 1176.79C612.377 1180.36 618.223 1182.01 624.061 1181.41L866.982 1156.48C874.75 1155.69 881.583 1151 885.127 1144.05L978.544 960.705C982.089 953.748 981.862 945.467 977.941 938.715L855.323 727.535C852.377 722.46 847.601 718.703 841.975 717.034L588.944 641.957C582.589 640.071 575.729 641.019 570.123 644.558L385.768 760.948C378.641 765.447 374.546 773.484 375.095 781.894Z"
          opacity="0.695652"
          stroke="url(#paint47_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M365.882 775.17L367.917 990.962C367.98 997.607 370.914 1003.9 375.963 1008.22L573.21 1177C577.699 1180.84 583.493 1182.81 589.392 1182.49L829.796 1169.64C837.565 1169.22 844.597 1164.91 848.487 1158.17L950.56 981.375C954.45 974.637 954.67 966.39 951.146 959.455L842.077 744.831C839.401 739.564 834.801 735.529 829.231 733.563L584.437 647.132C578.171 644.92 571.254 645.526 565.468 648.794L377.569 754.927C370.279 759.045 365.803 766.798 365.882 775.17Z"
          opacity="0.710145"
          stroke="url(#paint48_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M357.187 769.299L347.285 982.654C346.976 989.308 349.567 995.77 354.389 1000.37L539.141 1176.52C543.446 1180.62 549.173 1182.9 555.12 1182.87L792.419 1181.76C800.169 1181.73 807.38 1177.79 811.601 1171.29L921.906 1001.43C926.127 994.934 926.79 986.746 923.671 979.651L828.168 762.416C825.775 756.971 821.365 752.665 815.865 750.402L579.796 653.27C573.635 650.736 566.679 650.996 560.725 653.985L369.843 749.81C362.411 753.541 357.572 760.993 357.187 769.299Z"
          opacity="0.724638"
          stroke="url(#paint49_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M348.954 764.3L327.378 974.569C326.696 981.212 328.935 987.823 333.513 992.685L505.549 1175.37C509.654 1179.73 515.299 1182.31 521.282 1182.58L754.914 1192.85C762.626 1193.19 769.994 1189.64 774.532 1183.4L892.625 1020.85C897.163 1014.61 898.264 1006.5 895.559 999.273L813.588 780.252C811.489 774.643 807.284 770.074 801.869 767.517L574.966 660.354C568.927 657.502 561.948 657.415 555.84 660.117L362.531 745.613C354.979 748.953 349.797 756.086 348.954 764.3Z"
          opacity="0.73913"
          stroke="url(#paint50_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M341.126 760.188L308.174 966.741C307.119 973.352 308.997 980.094 313.318 985.208L472.466 1173.58C476.359 1178.18 481.905 1181.08 487.911 1181.64L717.343 1202.92C724.998 1203.63 732.503 1200.47 737.342 1194.49L862.765 1039.61C867.604 1033.63 869.136 1025.64 866.85 1018.29L798.328 798.302C796.535 792.543 792.551 787.72 787.235 784.87L569.893 668.364C563.993 665.201 557.008 664.766 550.76 667.172L355.572 742.349C347.922 745.295 342.418 752.092 341.126 760.188Z"
          opacity="0.753623"
          stroke="url(#paint51_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M333.646 756.975L289.649 959.206C288.222 965.764 289.731 972.618 293.78 977.971L439.927 1171.18C443.592 1176.03 449.024 1179.23 455.039 1180.08L679.767 1211.97C687.348 1213.04 694.967 1210.28 700.091 1204.59L832.371 1057.67C837.495 1051.98 839.45 1044.12 837.588 1036.69L782.385 816.526C780.908 810.633 777.159 805.565 771.958 802.426L564.526 677.277C558.78 673.81 551.806 673.025 545.433 675.13L348.908 740.024C341.18 742.576 335.376 749.022 333.646 756.975Z"
          opacity="0.768116"
          stroke="url(#paint52_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M326.456 754.669L271.778 951.997C269.982 958.481 271.114 965.427 274.878 971.004L407.959 1168.22C411.383 1173.3 416.686 1176.79 422.697 1177.95L642.249 1220.01C649.737 1221.44 657.449 1219.07 662.84 1213.68L801.492 1075.03C806.883 1069.64 809.252 1061.93 807.817 1054.44L765.756 834.887C764.605 828.876 761.105 823.572 756.032 820.149L558.814 687.068C553.237 683.304 546.291 682.171 539.807 683.968L342.479 738.646C334.695 740.802 328.613 746.885 326.456 754.669Z"
          opacity="0.782609"
          stroke="url(#paint53_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M319.499 753.274L254.534 945.144C252.371 951.533 253.121 958.55 256.587 964.337L376.592 1164.73C379.76 1170.02 384.92 1173.82 390.913 1175.27L604.847 1227.05C612.224 1228.84 620.007 1226.87 625.648 1221.79L770.175 1091.66C775.816 1086.58 778.587 1079.04 777.583 1071.52L748.441 853.346C747.624 847.234 744.388 841.706 739.458 838.002L552.708 697.708C547.315 693.657 540.415 692.177 533.835 693.66L336.225 738.213C328.407 739.976 322.069 745.682 319.499 753.274Z"
          opacity="0.797101"
          stroke="url(#paint54_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M312.718 752.792L237.888 938.677C235.362 944.95 235.726 952.017 238.882 957.999L345.85 1160.74C348.75 1166.24 353.75 1170.33 359.712 1172.08L567.619 1233.12C574.868 1235.25 582.701 1233.69 588.573 1228.93L738.47 1107.55C744.341 1102.79 747.503 1095.46 746.928 1087.92L730.44 871.867C729.967 865.671 727.007 859.93 722.234 855.951L546.163 709.167C540.968 704.837 534.131 703.012 527.47 704.178L330.089 738.725C322.256 740.096 315.687 745.416 312.718 752.792Z"
          opacity="0.811594"
          stroke="url(#paint55_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M306.057 753.22L221.81 932.622C218.928 938.759 218.901 945.855 221.735 952.014L315.756 1156.3C318.375 1161.99 323.201 1166.36 329.119 1168.41L530.622 1238.24C537.727 1240.71 545.588 1239.54 551.672 1235.12L706.424 1122.68C712.508 1118.26 716.047 1111.15 715.901 1103.63L711.758 890.411C711.636 884.149 708.966 878.207 704.364 873.959L539.132 721.412C534.15 716.813 527.393 714.646 520.666 715.49L324.011 740.176C316.186 741.158 309.409 746.081 306.057 753.22Z"
          opacity="0.826087"
          stroke="url(#paint56_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M299.46 754.553L206.269 927.004C203.037 932.985 202.616 940.088 205.12 946.409L286.332 1151.44C288.657 1157.31 293.294 1161.97 299.154 1164.32L493.91 1242.43C500.856 1245.22 508.723 1244.45 514.999 1240.38L674.088 1137.06C680.364 1132.99 684.265 1126.11 684.545 1118.63L692.399 908.942C692.636 902.633 690.268 896.503 685.851 891.991L531.575 734.406C526.82 729.548 520.159 727.043 513.381 727.563L317.935 742.555C310.137 743.153 303.178 747.673 299.46 754.553Z"
          opacity="0.84058"
          stroke="url(#paint57_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M292.872 756.782L191.233 921.847C187.66 927.65 186.844 934.74 189.007 941.203L257.596 1146.21C259.616 1152.25 264.048 1157.18 269.837 1159.83L457.537 1245.72C464.308 1248.82 472.159 1248.45 478.608 1244.73L641.51 1150.67C647.959 1146.95 652.206 1140.34 652.908 1132.92L672.373 927.424C672.973 921.086 670.919 914.783 666.701 910.015L523.453 748.111C518.936 743.006 512.389 740.168 505.576 740.361L311.806 745.85C304.056 746.07 296.938 750.18 292.872 756.782Z"
          opacity="0.855072"
          stroke="url(#paint58_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M286.241 759.894L176.67 917.17C172.763 922.777 171.553 929.831 173.366 936.419L229.562 1140.65C231.266 1146.84 235.479 1152.04 241.184 1154.98L421.553 1248.14C428.134 1251.54 435.949 1251.56 442.549 1248.2L608.739 1163.52C615.339 1160.16 619.915 1153.82 621.034 1146.5L651.687 945.822C652.656 939.475 650.928 933.012 646.921 927.995L514.727 762.487C510.462 757.148 504.044 753.981 497.211 753.846L305.568 750.046C297.885 749.894 290.633 753.589 286.241 759.894Z"
          opacity="0.869565"
          stroke="url(#paint59_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M279.513 763.875L162.544 912.993C158.316 918.384 156.711 925.379 158.166 932.075L202.246 1134.8C203.622 1141.13 207.602 1146.58 213.209 1149.83L386.006 1249.72C392.384 1253.41 400.142 1253.82 406.872 1250.82L575.823 1175.6C582.553 1172.61 587.44 1166.57 588.968 1159.36L630.353 964.101C631.695 957.766 630.305 951.158 626.523 945.9L505.363 777.493C501.361 771.931 495.089 768.443 488.253 767.978L299.17 755.123C291.572 754.606 284.212 757.883 279.513 763.875Z"
          opacity="0.884058"
          stroke="url(#paint60_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M272.637 768.705L148.823 909.331C144.284 914.487 142.286 921.403 143.378 928.185L175.657 1128.7C176.696 1135.16 180.429 1140.86 185.925 1144.39L350.942 1250.51C357.104 1254.47 364.785 1255.26 371.624 1252.63L542.808 1186.92C549.648 1184.3 554.827 1178.57 556.755 1171.5L608.384 982.228C610.103 975.924 609.06 969.186 605.515 963.697L495.33 793.084C491.603 787.313 485.491 783.51 478.668 782.716L292.559 761.058C285.066 760.186 277.622 763.044 272.637 768.705Z"
          opacity="0.898551"
          stroke="url(#paint61_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M265.566 774.365L135.471 906.199C130.633 911.102 128.247 917.916 128.969 924.766L149.805 1122.41C150.496 1128.96 153.969 1134.91 159.341 1138.73L316.403 1250.53C322.337 1254.75 329.921 1255.91 336.848 1253.66L509.743 1197.49C516.67 1195.24 522.121 1189.84 524.439 1182.93L585.794 1000.17C587.893 993.918 587.208 987.067 583.912 981.356L484.599 809.215C481.157 803.248 475.222 799.139 468.426 798.016L285.686 767.827C278.316 766.61 270.813 769.048 265.566 774.365Z"
          opacity="0.913043"
          stroke="url(#paint62_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M258.252 780.829L122.453 903.609C117.33 908.241 114.56 914.93 114.907 921.828L124.695 1115.95C125.03 1122.6 128.228 1128.77 133.463 1132.88L282.432 1249.83C288.127 1254.3 295.594 1255.83 302.587 1253.95L476.671 1207.31C483.664 1205.43 489.367 1200.38 492.064 1193.66L562.601 1017.89C565.08 1011.72 564.762 1004.77 561.728 998.846L473.144 825.839C469.996 819.691 464.253 815.284 457.5 813.834L278.505 775.402C271.274 773.85 263.737 775.869 258.252 780.829Z"
          opacity="0.927536"
          stroke="url(#paint63_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M250.648 788.072L109.733 901.571C104.34 905.915 101.191 912.458 101.161 919.383L100.33 1109.38C100.301 1116.1 103.211 1122.49 108.297 1126.88L249.065 1248.45C254.51 1253.15 261.842 1255.04 268.879 1253.54L443.639 1216.4C450.676 1214.9 456.608 1210.2 459.67 1203.69L538.822 1035.37C541.681 1029.29 541.739 1022.26 538.979 1016.14L460.942 842.909C458.098 836.595 452.56 831.898 445.866 830.124L270.97 783.753C263.895 781.877 256.349 783.481 250.648 788.072Z"
          opacity="0.942029"
          stroke="url(#paint64_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M242.713 796.065L97.2767 900.091C91.629 904.131 88.1086 910.506 87.6976 917.437L76.7116 1102.73C76.31 1109.51 78.9201 1116.11 83.8436 1120.78L216.336 1246.44C221.522 1251.36 228.701 1253.59 235.762 1252.47L410.688 1224.77C417.748 1223.65 423.887 1219.31 427.3 1213.03L514.476 1052.58C517.715 1046.61 518.156 1039.52 515.681 1033.21L447.974 860.375C445.441 853.91 440.123 848.935 433.503 846.838L263.039 792.846C256.135 790.659 248.604 791.852 242.713 796.065Z"
          opacity="0.956522"
          stroke="url(#paint65_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M234.406 804.776L85.0473 899.176C79.162 902.895 75.2784 909.081 74.4852 915.998L53.8381 1096.06C53.0572 1102.87 55.3543 1109.67 60.1034 1114.61L184.278 1243.85C189.197 1248.97 196.205 1251.53 203.267 1250.79L377.861 1232.44C384.922 1231.7 391.245 1227.73 394.992 1221.7L489.583 1069.47C493.201 1063.65 494.034 1056.52 491.854 1050.02L434.221 878.188C432.007 871.587 426.923 866.344 420.392 863.93L254.671 802.646C247.953 800.161 240.461 800.949 234.406 804.776Z"
          opacity="0.971014"
          stroke="url(#paint66_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M225.687 814.171L73.0102 898.827C66.905 902.213 62.6677 908.187 61.492 915.069L31.7056 1089.4C30.5392 1096.23 32.5111 1103.22 37.0736 1108.43L152.918 1240.72C157.563 1246.02 164.384 1248.9 171.425 1248.53L345.197 1239.43C352.238 1239.06 358.721 1235.48 362.786 1229.72L464.168 1086.04C468.16 1080.38 469.391 1073.23 467.517 1066.56L419.672 896.297C417.783 889.577 412.944 884.078 406.519 881.349L245.83 813.115C239.311 810.347 231.88 810.737 225.687 814.171Z"
          opacity="0.985507"
          stroke="url(#paint67_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <path
          d="M216.521 824.215L61.13 899.048C54.8237 902.085 50.2435 907.828 48.686 914.652L10.3077 1082.8C8.75018 1089.62 10.3848 1096.78 14.7489 1102.26L122.283 1237.1C126.647 1242.57 133.265 1245.76 140.265 1245.76H312.735C319.735 1245.76 326.353 1242.57 330.717 1237.1L438.251 1102.26C442.615 1096.78 444.25 1089.62 442.692 1082.8L404.314 914.652C402.756 907.828 398.176 902.085 391.87 899.048L236.479 824.215C230.173 821.178 222.827 821.178 216.521 824.215Z"
          stroke="url(#paint68_linear_413_99)"
          strokeOpacity="0.2"
          strokeWidth="4"
        ></path>
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_413_99"
            x1="1884.92"
            x2="1406.8"
            y1="1153.21"
            y2="79.352"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint1_linear_413_99"
            x1="1834.72"
            x2="1417.18"
            y1="1165.9"
            y2="78.1689"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint2_linear_413_99"
            x1="1784.47"
            x2="1427.63"
            y1="1176.95"
            y2="78.732"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint3_linear_413_99"
            x1="1734.24"
            x2="1438.06"
            y1="1186.4"
            y2="81.0285"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint4_linear_413_99"
            x1="1684.12"
            x2="1448.35"
            y1="1194.25"
            y2="85.0403"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint5_linear_413_99"
            x1="1634.21"
            x2="1458.44"
            y1="1200.52"
            y2="90.744"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint6_linear_413_99"
            x1="1584.58"
            x2="1468.21"
            y1="1205.25"
            y2="98.1118"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint7_linear_413_99"
            x1="1535.32"
            x2="1477.6"
            y1="1208.46"
            y2="107.11"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint8_linear_413_99"
            x1="1486.5"
            x2="1486.5"
            y1="1210.19"
            y2="117.702"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint9_linear_413_99"
            x1="1438.21"
            x2="1494.84"
            y1="1210.48"
            y2="129.846"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint10_linear_413_99"
            x1="1390.51"
            x2="1502.54"
            y1="1209.36"
            y2="143.494"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint11_linear_413_99"
            x1="1343.48"
            x2="1509.52"
            y1="1206.89"
            y2="158.597"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint12_linear_413_99"
            x1="1297.19"
            x2="1515.71"
            y1="1203.12"
            y2="175.101"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint13_linear_413_99"
            x1="1251.7"
            x2="1521.03"
            y1="1198.1"
            y2="192.947"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint14_linear_413_99"
            x1="1207.07"
            x2="1525.43"
            y1="1191.89"
            y2="212.075"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint15_linear_413_99"
            x1="1163.36"
            x2="1528.85"
            y1="1184.54"
            y2="232.421"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint16_linear_413_99"
            x1="1120.62"
            x2="1531.22"
            y1="1176.13"
            y2="253.917"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint17_linear_413_99"
            x1="1078.9"
            x2="1532.49"
            y1="1166.71"
            y2="276.494"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint18_linear_413_99"
            x1="1038.25"
            x2="1532.62"
            y1="1156.35"
            y2="300.079"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint19_linear_413_99"
            x1="998.703"
            x2="1531.56"
            y1="1145.12"
            y2="324.599"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint20_linear_413_99"
            x1="960.298"
            x2="1529.27"
            y1="1133.1"
            y2="349.978"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint21_linear_413_99"
            x1="923.069"
            x2="1525.71"
            y1="1120.34"
            y2="376.138"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint22_linear_413_99"
            x1="887.042"
            x2="1520.87"
            y1="1106.94"
            y2="403.001"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint23_linear_413_99"
            x1="852.241"
            x2="1514.7"
            y1="1092.95"
            y2="430.487"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint24_linear_413_99"
            x1="818.686"
            x2="1507.2"
            y1="1078.46"
            y2="458.515"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint25_linear_413_99"
            x1="786.39"
            x2="1498.34"
            y1="1063.53"
            y2="487.006"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint26_linear_413_99"
            x1="755.364"
            x2="1488.12"
            y1="1048.26"
            y2="515.879"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint27_linear_413_99"
            x1="725.614"
            x2="1476.53"
            y1="1032.7"
            y2="545.052"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint28_linear_413_99"
            x1="697.14"
            x2="1463.56"
            y1="1016.94"
            y2="574.446"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint29_linear_413_99"
            x1="669.941"
            x2="1449.23"
            y1="1001.05"
            y2="603.981"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint30_linear_413_99"
            x1="644.008"
            x2="1433.53"
            y1="985.096"
            y2="633.579"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint31_linear_413_99"
            x1="619.332"
            x2="1416.48"
            y1="969.158"
            y2="663.161"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint32_linear_413_99"
            x1="595.896"
            x2="1398.1"
            y1="953.305"
            y2="692.653"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint33_linear_413_99"
            x1="573.681"
            x2="1378.41"
            y1="937.606"
            y2="721.98"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint34_linear_413_99"
            x1="552.664"
            x2="1357.42"
            y1="922.127"
            y2="751.07"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint35_linear_413_99"
            x1="532.82"
            x2="1335.18"
            y1="906.934"
            y2="779.852"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint36_linear_413_99"
            x1="514.117"
            x2="1311.71"
            y1="892.09"
            y2="808.26"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint37_linear_413_99"
            x1="496.523"
            x2="1287.05"
            y1="877.656"
            y2="836.226"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint38_linear_413_99"
            x1="480"
            x2="1261.24"
            y1="863.69"
            y2="863.69"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint39_linear_413_99"
            x1="464.509"
            x2="1234.32"
            y1="850.247"
            y2="890.591"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint40_linear_413_99"
            x1="450.008"
            x2="1206.33"
            y1="837.38"
            y2="916.873"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint41_linear_413_99"
            x1="436.451"
            x2="1177.33"
            y1="825.139"
            y2="942.482"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint42_linear_413_99"
            x1="423.79"
            x2="1147.36"
            y1="813.569"
            y2="967.369"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint43_linear_413_99"
            x1="411.977"
            x2="1116.49"
            y1="802.715"
            y2="991.488"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint44_linear_413_99"
            x1="400.959"
            x2="1084.76"
            y1="792.615"
            y2="1014.79"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint45_linear_413_99"
            x1="390.682"
            x2="1052.23"
            y1="783.307"
            y2="1037.25"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint46_linear_413_99"
            x1="381.092"
            x2="1018.96"
            y1="774.823"
            y2="1058.82"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint47_linear_413_99"
            x1="372.131"
            x2="985.021"
            y1="767.192"
            y2="1079.47"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint48_linear_413_99"
            x1="363.743"
            x2="950.464"
            y1="760.44"
            y2="1099.18"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint49_linear_413_99"
            x1="355.867"
            x2="915.355"
            y1="754.588"
            y2="1117.92"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint50_linear_413_99"
            x1="348.446"
            x2="879.758"
            y1="749.656"
            y2="1135.68"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint51_linear_413_99"
            x1="341.419"
            x2="843.737"
            y1="745.656"
            y2="1152.43"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint52_linear_413_99"
            x1="334.726"
            x2="807.357"
            y1="742.601"
            y2="1168.16"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint53_linear_413_99"
            x1="328.307"
            x2="770.682"
            y1="740.497"
            y2="1182.87"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint54_linear_413_99"
            x1="322.103"
            x2="733.777"
            y1="739.347"
            y2="1196.56"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint55_linear_413_99"
            x1="316.053"
            x2="696.705"
            y1="739.152"
            y2="1209.22"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint56_linear_413_99"
            x1="310.1"
            x2="659.531"
            y1="739.906"
            y2="1220.86"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint57_linear_413_99"
            x1="304.184"
            x2="622.315"
            y1="741.604"
            y2="1231.48"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint58_linear_413_99"
            x1="298.25"
            x2="585.119"
            y1="744.234"
            y2="1241.1"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint59_linear_413_99"
            x1="292.242"
            x2="548.003"
            y1="747.781"
            y2="1249.74"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint60_linear_413_99"
            x1="286.105"
            x2="511.025"
            y1="752.23"
            y2="1257.41"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint61_linear_413_99"
            x1="279.787"
            x2="474.242"
            y1="757.558"
            y2="1264.13"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint62_linear_413_99"
            x1="273.237"
            x2="437.708"
            y1="763.744"
            y2="1269.93"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint63_linear_413_99"
            x1="266.407"
            x2="401.475"
            y1="770.759"
            y2="1274.84"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint64_linear_413_99"
            x1="259.25"
            x2="365.594"
            y1="778.576"
            y2="1278.89"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint65_linear_413_99"
            x1="251.721"
            x2="330.112"
            y1="787.163"
            y2="1282.11"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint66_linear_413_99"
            x1="243.779"
            x2="295.075"
            y1="796.485"
            y2="1284.53"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint67_linear_413_99"
            x1="235.384"
            x2="260.524"
            y1="806.507"
            y2="1286.21"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint68_linear_413_99"
            x1="226.5"
            x2="226.5"
            y1="817.19"
            y2="1287.18"
          >
            <stop stopColor="#FF1CF7"></stop>
            <stop offset="1" stopColor="#00F0FF" stopOpacity="0.2"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default BgHeader;
