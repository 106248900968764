import React, { FC, useEffect, useState } from "react";
import { Box, Pagination, Stack } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import usePagination from "./Pagination";
import CardTable from "./CardTable";

const Documents: FC<{
  title: string;
  dataName: string;
}> = ({ title, dataName }) => {
  const [data, setData] = useState<any[]>([]);

  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(data.length / PER_PAGE);
  const _DATA = usePagination(data, PER_PAGE);

  const handleChange = (e: any, p: React.SetStateAction<number>) => {
    setPage(p);
    _DATA.jump(p);
  };

  const getData = () => {
    fetch(`/assets/data/${dataName}.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Box
        sx={{
          height: "65vh",
          backgroundImage: "url('/assets/img/knowledge/background.jpg')",
          backgroundPosition: "right 100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "50px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#00000080",
            height: "100%",
            paddingTop: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              color: "var(--light-color)",
            }}
          >
            <h1 className="title">{title}</h1>
          </Box>
        </Box>
      </Box>
      <Layout
        props={{
          paddingTop: "70px",
          position: "relative",
          margin: "0 auto",
        }}
      >
        <Box>
          <CardTable dataName={dataName} data={_DATA} />
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
            <Stack spacing={2}>
              <Pagination
                color="secondary"
                count={count}
                size="large"
                page={page}
                shape="rounded"
                onChange={handleChange}
              />
            </Stack>
          </Box>
        </Box>
      </Layout>
    </div>
  );
};

export default Documents;
