import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import React, { FC } from "react";
import Link from "../../components/Link/Link";

const CardImage: FC<{
  title: string;
  image: any;
  desc: any;
  date: string;
  slug: string;
  dataName: string;
}> = ({ title, image, desc, date, slug, dataName }) => {
  return (
    <Card sx={{ maxWidth: 445 }}>
      <CardMedia sx={{ height: 240 }} image={image} title={title} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {desc}
        </Typography>
      </CardContent>
      <CardActions>
        <Link href={`${slug}`}>Xem thêm</Link>
      </CardActions>
    </Card>
  );
};

export default CardImage;
