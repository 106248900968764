import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router-dom";

function Reading() {
  const { dataName } = useParams();
  const { slug } = useParams();

  useEffect(() => {
    fetch(`/assets/data/${dataName}.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        const foundItem = myJson.find((item: any) => item.slug === slug);
        if (foundItem.content) {
          document.getElementById("content").innerHTML = foundItem.content;
        }
      });
  }, []);

  return (
    <div className="reading">
      <Layout
        props={{
          maxWidth: "1024px!important",
          margin: "0 auto",
          marginTop: "150px",
        }}
      >
        <div id="content"></div>
      </Layout>
    </div>
  );
}

export default Reading;
