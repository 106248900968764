import React from "react";
import Layout from "../../components/Layout/Layout";
import {
  Box,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
} from "@mui/material";

function Knowledge() {
  return (
    <div>
      <Box
        sx={{
          height: "65vh",
          backgroundImage:
            "url('https://lh3.googleusercontent.com/d/1TOrMWhS5N5elzEo1aMr9I0Gm-KPAthik')",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "50px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#00000080",
            height: "100%",
            paddingTop: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              color: "var(--light-color)",
            }}
          >
            <h1 className="title">
              Tổng hợp kiến thức về Social Media Marketing
            </h1>
            <center>
              <p style={{ color: "var(--light-color)", maxWidth: "1024px" }}>
                Chia sẻ những kiến thức Social Media Marketing thực chiến từ cơ
                bản đến nâng cao từ các chuyên gia Marketing. Bạn cần trang bị
                thêm những kiến thức bổ ích này. Hãy xem ngay!
              </p>
            </center>
          </Box>
        </Box>
      </Box>
      <Layout props={{ maxWidth: "1204px!important", margin: "0 auto" }}>
        <Box sx={{ mt: 10 }}>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <h1>Kiến thức Social Media Marketing</h1>
              <p>
                Bạn là người làm Social Media Marketing? Bạn muốn tìm kiếm những
                kho dữ liệu đáng tin cậy và có giá trị? Bạn băn khoăn giữa hàng
                chục ngàn trang web? Nếu bạn đang gặp phải những vấn đề trên thì
                bài viết này sẽ giúp bạn. Dưới đây là tổng hợp danh sách của hơn
                106 trang web và blog hàng đầu thế giới về Social Media
                Marketing. Tại đây, bạn sẽ nhận được những tin tức, bài viết,
                kiến thức giá trị từ những chuyên gia, cộng đồng người làm
                Social Media Marketing.
              </p>
            </Grid>
            <Grid item md={6}>
              <img
                loading="lazy"
                width="100%"
                style={{ borderRadius: "20px" }}
                src="https://lh3.googleusercontent.com/d/1RWoX3lOAlGv10vVMws7qDNOdqmPraZju"
              />
            </Grid>
          </Grid>
        </Box>
        <Box className="section">
          <h1>
            <Divider>Chuyên mục</Divider>
          </h1>
          <Grid container spacing={4}>
            {Item.map((item, index) => (
              <Grid item md={4} xs={12} key={index}>
                <ImageList cols={1}>
                  <ImageListItem>
                    <img
                      src={`${item.image}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                    />
                    <Link
                      href={item.href}
                      underline="none"
                      sx={{
                        color: "var(--light-color)",
                        p: 0,
                      }}
                    >
                      <ImageListItemBar
                        title={item.title}
                        sx={{
                          "*": { whiteSpace: "unset!important" },
                          height: "100%",
                          textAlign: "center",
                        }}
                      />
                    </Link>
                  </ImageListItem>
                </ImageList>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Layout>
    </div>
  );
}

const Item = [
  {
    title: "Kiến thức Facebook",
    image: "/assets/img/about/12.jpg",
    href: "/kien-thuc/facebook",
  },
  {
    title: "Kiến thức Tiktok",
    image: "/assets/img/about/6.jpg",
    href: "/kien-thuc/tiktok",
  },
  {
    title: "Marketing tổng thể",
    image: "/assets/img/about/11.jpg",
    href: "/kien-thuc/marketing-tong-the",
  },
];

export default Knowledge;
